import axios from "axios";
import React, { useEffect, useState } from "react";
import { linkUrl } from "../component/common/linkUrl";
import MessageBox from "../component/common/MessageBox";
import siteSetting from "../services/settingService";

const ContactPage = () => {
  const [contact, setContact] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [succmesg, setSuccmesg] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);

    const settingData = async () => {
      window.scrollTo(0, 0);
      setIsLoading(true);
      const sitedata = await siteSetting();
      if (sitedata) {
        setContact(sitedata);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    settingData();
  }, []);

  const submitValue = async (e) => {
    e.preventDefault();
    const contactForm = {
      name,
      email,
      subject,
      message,
    };
    if (contactForm !== null) {
      try {
        const res = await axios.post(`${linkUrl}/contactus`, contactForm);
        setFullName("");
        setEmail("");

        setSubject("");
        setMessage("");

        if (res.data.status === 200) {
          setIsLoading(false);
          setSuccmesg("We Receive your message");
          //  setOffers(res.data.data[0]);
          setError(null);
        }
      } catch (err) {
        setError("Something went worng ");

        // setOffers(null);
      } finally {
        setIsLoading(false);
      }
    }
    setFullName("");
    setEmail("");

    setSubject("");
    setMessage("");
  };
  return (
    <>
      <div className="contact-us-wrapper pt-3 mb-0">
        <div className="breadcrumb-wrap style-01">
          <div className="container custom-container-01">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-content">
                  <h3 className="title text-center">Contact us</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="contact-form-area-wrapper section-bottom-space">
          <div className="container custom-container-01">
            <div className="row">
              <div className="col-lg-12">
                <div className="apply-form-inner">
                  <div className="row justify-content-between">
                    <div className="col-lg-5">
                      <div className="contact-address">
                        <h3 className="title">Get in touch</h3>

                        <ul className="ul contact-address-list">
                          <li className="single-address-item">
                            <span className="icon-wrap color-01">
                              <i className="fa-sharp fa-solid fa-location-dot icon"></i>
                            </span>
                            <span className="text">
                              {contact && contact.site_address
                                ? contact.site_address
                                : ""}
                            </span>
                          </li>
                          <li className="single-address-item">
                            <span className="icon-wrap color-02">
                              <i className="fa-solid fa-phone icon"></i>
                            </span>
                            <span className="text">
                              {contact && contact.site_phone
                                ? contact.site_phone
                                : ""}
                            </span>
                          </li>
                          <li className="single-address-item">
                            <span className="icon-wrap color-03">
                              <i className="fa-solid fa-envelope-open icon"></i>
                            </span>
                            <span className="text">
                              {contact && contact.site_email
                                ? contact.site_email
                                : ""}
                            </span>
                          </li>
                        </ul>

                        <ul className="ul social-media-list style-01 color-02">
                          {contact && contact.site_fb_link && (
                            <li className="single-social-item">
                              <a
                                tabIndex="-1"
                                href={`${
                                  contact.site_fb_link
                                    ? contact.site_fb_link
                                    : ""
                                }`}
                                target="_blank"
                              >
                                <i className="fa-brands fa-facebook-f icon"></i>
                              </a>
                            </li>
                          )}

                          {contact && contact.site_in_link && (
                            <li className="single-social-item">
                              <a
                                tabIndex="-1"
                                href={`${
                                  contact.site_in_link
                                    ? contact.site_in_link
                                    : ""
                                }`}
                                target="_blank"
                              >
                                <i className="fa-brands fa-instagram icon"></i>
                              </a>
                            </li>
                          )}

                          {contact && contact.site_lin_link && (
                            <li className="single-social-item">
                              <a
                                tabIndex="-1"
                                href={`${
                                  contact.site_lin_link
                                    ? contact.site_lin_link
                                    : ""
                                }`}
                                target="_blank"
                              >
                                <i className="fa-brands fa-linkedin-in icon"></i>
                              </a>
                            </li>
                          )}

                          {contact && contact.site_tw_link && (
                            <li className="single-social-item">
                              <a
                                tabIndex="-1"
                                href={`${
                                  contact.site_tw_link
                                    ? contact.site_tw_link
                                    : ""
                                }`}
                                target="_blank"
                              >
                                <i className="fa-brands fa-twitter icon"></i>
                              </a>
                            </li>
                          )}

{contact && contact.site_you_link && (
                            <li className="single-social-item">
                              <a
                                tabIndex="-1"
                                href={`${
                                  contact.site_you_link
                                    ? contact.site_you_link
                                    : ""
                                }`}
                                target="_blank"
                              >
                                <i className="fa-brands fa-youtube"></i>
                              </a>
                            </li>
                          )}

{contact && contact.site_tik_link && (
                            <li className="single-social-item">
                              <a
                                tabIndex="-1"
                                href={`${
                                  contact.site_tik_link
                                    ? contact.site_tik_link
                                    : ""
                                }`}
                                target="_blank"
                              >
                                <i className="fa-brands fa-tiktok"></i>
                              </a>
                            </li>
                          )}

                          
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="contact-form">
                        {error ? (
                          <MessageBox varient="danger">{error}</MessageBox>
                        ) : (
                          ""
                        )}

                        <>
                          {succmesg ? (
                            <MessageBox varient="success">
                              {succmesg}
                            </MessageBox>
                          ) : (
                            ""
                          )}
                        </>

                        <form className="form" onSubmit={submitValue}>
                          <div className="part">
                            {/* <h5 className="title">Primary Information</h5> */}
                            <div className="form-element">
                              <div className="row">
                                <div className="col-lg-6">
                                  <i className="fa-solid fa-user"></i>
                                  <input
                                    type="text"
                                    placeholder="Full name"
                                    name="name"
                                    value={name}
                                    onChange={(e) =>
                                      setFullName(e.target.value)
                                    }
                                    required
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <i className="fa-solid fa-envelope-open"></i>
                                  <input
                                    type="email"
                                    placeholder="Email address"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-element">
                              <div className="row">
                                <div className="col-lg-12">
                                  <i className="fa fa-book"></i>
                                  <input
                                    type="text"
                                    name="subject"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    placeholder="Subject"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-element">
                              <div className="row">
                                <div className="col-lg-12">
                                  <textarea
                                    name="message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    className="textarea"
                                    placeholder="Write description..."
                                    rows="10"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-submit text-right">
                            <button type="submit" className="btn-common">
                              submit Message
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="breadcrumb-wrap style-01">
          <div className="container custom-container-01">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-content">
                  <h3 className="title text-center">Location Map</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="map-wrapper section-top-space">
          <iframe
            className="w-100"
            src={contact && contact.site_map ? contact.site_map : ""}
            height="510"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
