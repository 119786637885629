import React, { useEffect, useState } from 'react'
import { imgUrl } from '../component/common/linkUrl';
import galleryServices from '../services/galleryServices';

const GalleryPage = () => {

    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      window.scrollTo(0, 0);
      const galleryData = async () => {
        setIsLoading(true);
        const images = await galleryServices();
        if (images) {
            setData(images);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      };
      galleryData();
    }, []);

  return (
    <>
        <div className="row justify-content-center m-4">
    <div className="col-lg-6">
      <div className="theme-section-title desktop-center text-center">
        <h4 className="title">Gallery</h4>
      </div>
    </div>
  </div>
  <section className="container pb-4">
      <div className="row g-4">
      {data &&
          data.length > 0 &&
          data.map((g,idx)=>(
            <div className="col-md-4 col-lg-4" key={idx}  data-aos="zoom-in" data-aos-duration="2000">
                 <div className="card service-card">
                    <div className="card-body p-0">
                      {g && g.img && (
                        <img className="p-3 w-100"
                          src={
                            g.img
                              ? `${imgUrl}${g.img}`
                              : ""
                          }
                        />
                      )}
                      {g && g.caption && (
                        <h6 className="name p-3 text-center text-primary">
                          {g.caption ? g.caption : ""}
                        </h6>
                      )}
                    </div>
                  </div>

                </div>
          ))
      }
        </div>
        </section>
    
    </>


  )
}

export default GalleryPage