import MyRoutes from "./MyRoutes";
import AOS from 'aos';
import 'aos/dist/aos.css'
import { useEffect } from "react";

function App() {
  useEffect(()=>{
    AOS.init({duration:2000})
  },[]);
  return (
   <div>
    <MyRoutes/>
    <div className="back-to-top">
      <span className="back-top"><i className="fa fa-angle-up"></i></span>
  </div>
    </div>
  );
}

export default App;
