import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { imgUrl, linkUrl } from "../component/common/linkUrl";

const AbroadStudyDetailPage = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const abroadStudyDetails = async () => {
      setIsLoading(true);
      if (id) {
        const res = await axios.get(`${linkUrl}/abroadstudy/${id}`);
        if (res.data.status === 200) {
          setData(res.data.data);

          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    };
    abroadStudyDetails();
    setData("");
  }, [id]);
  return (
    <>
      <div className="container mb-4">
        <div className="blog-grid-item-02 style-02 blog-details ">
          {data && data.abroad_studies_title && (
            <h3 className="main-title mt-4">
              {data.abroad_studies_title ? data.abroad_studies_title : ""}
            </h3>
          )}
        </div>

        {data && (
          <div className="row">
            <div
              className="col-md-12 blog-details-content p-4 fs-6"
              style={{ backgroundColor: "#edeffc" }}
            >
              {data.abroad_studies_img && (
                <img
                  style={{
                    float: "left",
                    width: "250px",
                    height: "200px",
                    marginRight: "10px",
                  }}
                  src={
                    data.abroad_studies_img
                      ? `${imgUrl}${data.abroad_studies_img}`
                      : ""
                  }
                  alt="Institute"
                />
              )}

              <p
                className="text-dark fw-bold"
                dangerouslySetInnerHTML={{ __html: data.abroad_studies_des }}
              ></p>
              {data && data.abroad_studies_admi && (
                <>
                  <h3 className="main-title mt-4 mb-2">
                    Admission Requirement
                  </h3>
                  <div
                    className="text-dark fw-bold"
                    dangerouslySetInnerHTML={{
                      __html: data.abroad_studies_admi,
                    }}
                  ></div>
                </>
              )}

              {data && data.abroad_studies_docs && (
                <>
                  <h3 className="main-title mt-4 mb-2">Required Documents</h3>
                  <div
                    className="text-dark fw-bold"
                    dangerouslySetInnerHTML={{
                      __html: data.abroad_studies_docs,
                    }}
                  ></div>
                </>
              )}

              {data && data.abroad_studies_pros && (
                <>
                  <h3 className="main-title mt-4 mb-2"> Processing</h3>
                  <div
                    className="text-dark fw-bold"
                    dangerouslySetInnerHTML={{
                      __html: data.abroad_studies_pros,
                    }}
                  ></div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AbroadStudyDetailPage;
