import axios from "axios";
import { linkUrl } from "../component/common/linkUrl";


const galleryServices = async () => {
    const res = await axios.get(`${linkUrl}/gallery`);
    if (res.data.status === 200) {
        return res.data.data;
    }
}

export default galleryServices;
