import React from 'react'
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import FooterComponent from './component/common/FooterComponent';
import NavBarComponent from './component/common/NavBarComponent';
import TopBarComponent from './component/common/TopNavBarComponent';

import AboutUsPage from './pages/AboutUsPage';
import AbroadStudyDetailPage from './pages/AbroadStudyDetailPage';
import ContactPage from './pages/ContactPage';
import CourseDetailPage from './pages/CourseDetailPage';
import CoursesPage from './pages/CoursesPage';
import GalleryPage from './pages/GalleryPage';
import HomePage from './pages/HomePage';
import PageNotFound from './pages/PageNotFound';
import PartnerDetailPage from './pages/PartnerDetailPage';
import PartnerInstitutionsPage from './pages/PartnerInstitutionsPage';
import ServicesPage from './pages/ServicesPage';



const MyRoutes = () => {
  return (
    <Router>
       <TopBarComponent/>
                <NavBarComponent/>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/courses" element={<CoursesPage />} />
      <Route path="/course-details/:id" element={<CourseDetailPage/>}/> 
      <Route path="/study-abroad/:id" element={<AbroadStudyDetailPage/>}/> 

      <Route path="/services" element={<ServicesPage/>}/> 
      <Route path="/partner-institutions" element={<PartnerInstitutionsPage/>}/> 
      <Route path="/partner-institution-details/:id" element={<PartnerDetailPage/>}/> 
      <Route path="/gallery" element={<GalleryPage/>}/> 

      <Route path="/contact-us" element={<ContactPage/>}/> 
      
      <Route path="*" element={<PageNotFound  />} />

      </Routes>
      <FooterComponent/>
      </Router>
  )
}

export default MyRoutes