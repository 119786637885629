import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import coursesService from "../../services/coursesService";
import { imgUrl } from "../common/linkUrl";

const DisciplineComponent = () => {
  const [courses, setCourses] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const coursesData = async () => {
      setIsLoading(true);
      const course = await coursesService();
      if (course) {
        setCourses(course);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    coursesData();
  }, []);
  return (
    <section className=" category-section-area" style={{ marginTop: "40px" }}>
      <div className="container custom-container-01">
        <div className="row">
          <div className="col-lg-12">
            <div
              className="section-title-wrapper  justify-content-between"
              style={{ marginBottom: "0px" }}
            >
              <div className="theme-section-title text-center mb-3">
                <h5 className="title text-center">Popular Discipline</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="container mb-5">
          <div className="row">
            {courses &&
              courses.length > 0 &&
              courses.slice(0,4).filter(popular => popular.popular =='true').map((course, idx) => (
                <div className="col-lg-3 col-md-6 col-sm-6 mb-2" key={idx}>
                    <Link to={`/course-details/${course.id}`}>
                  <div
                    className=" style-01 me-3 border p-3  h-100 back-bg"  data-aos="fade-up"
                    style={{ borderRadius: "30px" }} 
                  >
                    <div className="thumbnail text-center">
                      <img
                        className="w-100"
                        src={
                          course.course_img
                            ? `${imgUrl}${course.course_img}`
                            : ""
                        }
                        alt="course"
                      />
                    </div>
                    <h6 className="name text-center pt-5">
                      {course.course_name ? course.course_name : ""}
                    </h6>
                  </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DisciplineComponent;
