import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { imgUrl } from '../component/common/linkUrl';
import coursesService from '../services/coursesService';

const CoursesPage = () => {
  window.scrollTo(0, 0);
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      const coursesData = async () => {
        setIsLoading(true);
        const sitedata = await coursesService();
        if (sitedata) {
          setData(sitedata);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      };
      coursesData();
    }, []);
  return (
    <>
    <div className="row justify-content-center my-3">
      <div className="col-lg-6">
        <div className="theme-section-title desktop-center text-center">
          <h4 className="title">Our Courses</h4>
        </div>
      </div>
    </div>


    <section className="container pb-4">
      <div className="row g-4">
        {data &&
          data.length > 0 &&
          data
          
            .map((ins, idx) => (
              <div className="col-md-4 col-lg-3" key={idx}  data-aos="zoom-in" data-aos-duration="2000">
                <Link to={`/course-details/${ins.id}`}>
                  <div className="card service-card h-100" style={{ backgroundColor: "#94b397",borderRadius:"15px" }}>
                    <div className="card-body p-0">
                      {ins && ins.course_img && (
                        <img className="p-3 w-100"
                          src={
                            ins.course_img
                              ? `${imgUrl}${ins.course_img}`
                              : ""
                          }
                        />
                      )}
                      {ins && ins.course_name && (
                        <h6 className="name p-3 text-center text-primary">
                          {ins.course_name ? ins.course_name : ""}
                        </h6>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
      </div>
    </section>


  </>
  )
}

export default CoursesPage