import React, { useEffect, useState } from 'react'
import whyus from '../../services/whyUsService';
import { imgUrl } from '../common/linkUrl';

const WhyUsComponent = () => {
    const [whyusinfo, setWhyUsInfo] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      const whyUsData = async () => {
        setIsLoading(true);
        const why_data = await whyus();
        if (why_data) {
            setWhyUsInfo(why_data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      };
      whyUsData();
    }, []);
  return (

    <section className='container-fluid  pt-3  pb-3 features-section mt-0 mt-md-5'>

    <section className=" container">
          
            <div className="container custom-container-01">
                <div className="row">
                {
                  whyusinfo && whyusinfo.title1 && 
                  <div className="col-lg-4 col-md-6 mb-2" data-aos="fade-right"> 
                  <div className="icon-box-item h-100 shadow" style={{ backgroundColor:"#dae5a9" }}>
                      <div className="icon">
                          <img src={whyusinfo.img1 ? `${imgUrl}${whyusinfo.img1}` : ""}/>
                      </div>
                      <div className="content">
                          <h4 className="title text-center mb-0">{ whyusinfo.title1?  whyusinfo.title1:''}</h4>
                          {/* <p dangerouslySetInnerHTML={{ __html: whyusinfo.why_us1}}></p> */}
                      </div>
            
                  </div>
              </div>
                  
              
              }

{
                  whyusinfo && whyusinfo.title2 && 
                  <div className="col-lg-4 col-md-6 mb-2" data-aos="zoom-in"> 
                  <div className="icon-box-item h-100 shadow" style={{ backgroundColor:"#dae5a9" }}>
                      <div className="icon">
                          <img src={whyusinfo.img2 ? `${imgUrl}${whyusinfo.img2}` : ""}/>
                      </div>
                      <div className="content">
                          <h4 className="title text-center mb-0">{ whyusinfo.title2?  whyusinfo.title2:''}</h4>
                          {/* <p dangerouslySetInnerHTML={{ __html: whyusinfo.why_us2}}></p> */}
                      </div>
            
                  </div>
              </div>
              }
{
                  whyusinfo && whyusinfo.title3 && 
                  <div className="col-lg-4 col-md-6 mb-2" data-aos="fade-left"> 
                  <div className="icon-box-item h-100 shadow" style={{ backgroundColor:"#dae5a9" }}>
                      <div className="icon">
                          <img src={whyusinfo.img3 ? `${imgUrl}${whyusinfo.img3}` : ""}/>
                      </div>
                      <div className="content">
                          <h4 className="title text-center mb-0">{ whyusinfo.title3?  whyusinfo.title3:''}</h4>
                          {/* <p dangerouslySetInnerHTML={{ __html: whyusinfo.why_us3}}></p> */}
                      </div>
            
                  </div>
              </div>
                  
              
              }
                
                </div>
            </div>
        </section>
        </section>
  )
}

export default WhyUsComponent