import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { imgUrl } from "../component/common/linkUrl";
import partnerInstitutesService from "../services/partnerInstitutesService";

const PartnerInstitutionsPage = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const institutesData = async () => {
      setIsLoading(true);
      const sitedata = await partnerInstitutesService();
      if (sitedata) {
        setData(sitedata);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    institutesData();
  }, []);
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="theme-section-title desktop-center text-center">
            <h4 className="title">Our Partner University</h4>
          </div>
        </div>
      </div>
  

      <section className="container pb-4">
        <div className="row g-4">
          {data &&
            data.length > 0 &&
            data
            
              .map((ins, idx) => (
                <div className="col-md-4 col-lg-3" key={idx}  data-aos="zoom-in" data-aos-duration="2000">
                  <Link to={`/partner-institution-details/${ins.id}`}>
                    <div className="card service-card h-100" style={{ backgroundColor: "#94b397",borderRadius:"15px" }}>
                      <div className="card-body p-0">
                        {ins && ins.institute_img && (
                          <img className="p-3"
                            src={
                              ins.institute_img
                                ? `${imgUrl}${ins.institute_img}`
                                : "University Image"
                            }
                          />
                        )}
                        {ins && ins.institute_name && (
                          <h6 className="name p-3 text-center text-primary">
                            {ins.institute_name ? ins.institute_name : ""}
                          </h6>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
        </div>
      </section>


    </>
  );
};

export default PartnerInstitutionsPage;
