import React, { useEffect } from 'react'
import AboutHomeComponent from '../component/HomeComponent/AboutHomeComponent'
import DisciplineComponent from '../component/HomeComponent/DisciplineComponent'
import SliderComponent from '../component/HomeComponent/SliderComponent'
import StepsHomeComponent from '../component/HomeComponent/StepsHomeComponent'
import TestimonialHomeComponent from '../component/HomeComponent/TestimonialHomeComponent'
import WhyUsComponent from '../component/HomeComponent/WhyUsComponent'

const HomePage = () => {
  
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <>

    <SliderComponent/>
    <WhyUsComponent/>
    <AboutHomeComponent/>
    {/* <StepsHomeComponent/> */}
    <DisciplineComponent/>
    <TestimonialHomeComponent/>
  

    </>
  )
}

export default HomePage