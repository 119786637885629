import axios from "axios";
import { linkUrl } from "../component/common/linkUrl";


const partnerInstitutesService = async () => {
    const res = await axios.get(`${linkUrl}/institutes`);
    if (res.data.status === 200) {
        return res.data.data;
    }
}

export default partnerInstitutesService;
