import React, { useEffect, useState } from "react";
import aboutService from "../../services/aboutServices";
import { imgUrl } from "../common/linkUrl";

const AboutHomeComponent = () => {
  const [about, setAbout] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const aboutData = async () => {
      setIsLoading(true);
      const about_data = await aboutService();
      if (about_data) {
        setAbout(about_data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    aboutData();
  }, []);
  return (
    <section
      className="about-section-area mt-5 about-home-02 py-5"
      style={{ backgroundColor: "#faeaff" }}
    >
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 col-md-12"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <div className="theme-section-title">
              <span className="subtitle">ABOUT US & EXPERIENCE</span>
              {about && about.about_title && (
                <h4 className="title">
                  {about.about_title ? about.about_title : ""}
                </h4>
              )}
            </div>
            <div className="about-content-wrap">
              {about && about.about_des && (
                <>
                  <p
                    className="mb-4"
                    dangerouslySetInnerHTML={{
                      __html:
                        about.about_des.slice(0, 10000) +
                        `<a class="btn btn-primary" href="/about-us" role="button">Read More</a>`,
                    }}
                  ></p>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="" data-aos="zoom-in" data-aos-duration="3000">
              {about && about.about_img && (
                <img
                  className="w-75"
                  src={about.about_img ? `${imgUrl}${about.about_img}` : ""}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHomeComponent;
