import React, { useEffect, useState } from "react";
import FooterComponent from "../component/common/FooterComponent";
import { imgUrl } from "../component/common/linkUrl";
import aboutService from "../services/aboutServices";

const AboutUsPage = () => {
  const [about, setAbout] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const aboutData = async () => {
      setIsLoading(true);
      const about_data = await aboutService();
      if (about_data) {
        setAbout(about_data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    aboutData();
  }, []);

  return (
    <>
      
      
      <div className="about-page-area-wrapper single-page-section-top-space single-page-section-bottom-space pb-0">
        <section className="about-section-area section-bottom-space">
          <div className="container custom-container-01">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="about-content">
                  {about && about.about_title && (
                    <h3 className="content-title">
                      {about.about_title ? about.about_title : ""}
                    </h3>
                  )}

                  <div className="" data-aos="fade-right"  data-aos-duration="1500"> 
                    {about && about.about_des && (
                      <>
                        <div
                          className="mb-4 paragraph"
                          dangerouslySetInnerHTML={{ __html: about.about_des }}
                        ></div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="thumbnail">
                  <div className="right-frame" data-aos="fade-left"  data-aos-duration="1500">
                    {about && about.about_img && (
                      <img
                        src={
                          about.about_img
                            ? `${imgUrl}${about.about_img}`
                            : "about us"
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="chose-area-wrapper section-bottom-space pb-0">
          <div className="chose-area-inner bg-color-01">
            <div className="container custom-container-01">
              <div className="row justify-content-end">
                <div className="col-lg-6 col-md-12 d-flex align-items-center">
                  <div className="thumbnail ">
                    <div className="" data-aos="fade-right">
                      {about && about.mis_vis_img && (
                        <img
                          src={
                            about.mis_vis_img
                              ? `${imgUrl}${about.mis_vis_img}`
                              : "about us"
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="content-wrap">
                    <div className="section-title-wrapper">
                      <h4 className="section-title">Why chose us</h4>
                    </div>

                    <div className="icon-box-with-text-wrap">
                      <ul className="ul icon-box-with-text style-02">
                        {about && about.vision && (
                          <li className="single-icon-box-with-text" data-aos="fade-right">
                            <div className="icon-wrap">
                              <img
                                src="assets/img/icon/icon-and-text/02/01.png"
                                alt=""
                              />
                            </div>
                            <div className="content">
                              <h4 className="title">Our Vision</h4>
                              <p className="paragraph">
                                {about.vision ? about.vision : ""}
                              </p>
                            </div>
                          </li>
                        )}

                        {about && about.mission && (
                          <li className="single-icon-box-with-text style-02" data-aos="fade-left">
                            <div className="icon-wrap">
                              <img
                                src="assets/img/icon/icon-and-text/02/02.png"
                                alt=""
                              />
                            </div>
                            <div className="content">
                              <h4 className="title">Our Mission</h4>
                              <p className="paragraph">
                                {about.mission ? about.mission : ""}
                              </p>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </>
  );
};

export default AboutUsPage;
