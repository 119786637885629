import React, { useEffect, useState } from "react";
import { imgUrl } from "../component/common/linkUrl";
import servicDataService from "../services/ourServices";

const ServicesPage = () => {
  const [services, setServices] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const servicesData = async () => {
      setIsLoading(true);
      const service = await servicDataService();
      if (service) {
        setServices(service);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    servicesData();
  }, []);

  return (
    <>
      
      <div className="col-lg-12 mt-4">
        <div className="theme-section-title desktop-center text-center">
          <h4 className="title">Our Services</h4>
        </div>
      </div>

      <div className="services-details-wrapper single-page-section-top-space ">
        <section className="about-section-area-wrapper section-bottom-space">
          <div className="container custom-container-01 ">

          {
             services && services.map((service, idx) => (
              <div className="customeServices" key={idx}>
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6 img-column">
                  <div className="thumbnail " >
                    <img   src={`${imgUrl}${service.service_img}`} alt="services" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content">
                    <h3 className="content-title">{service.service_name ? service.service_name : "" }</h3>

                    <div className="paragraph font-weight-600 color-heading" dangerouslySetInnerHTML={{ __html: service.service_des}}>
                    </div>

            


                  </div>
                </div>
              </div>
            </div>

             ))
          }


          </div>
        </section>
      </div>
    </>
  );
};

export default ServicesPage;
