import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import siteSetting from "../../services/settingService";

const FooterComponent = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const settingData = async () => {
      setIsLoading(true);
      const sitedata = await siteSetting();
      if (sitedata) {
        setData(sitedata);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    settingData();
  }, []);
  return (
    <footer className="footer-area ">
      <div className="footer-top">
        <div className="container custom-container-01">
          <div className="footer-middle container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="footer-widget widget widget_nav_menu">
                  <h4 className="widget-headline">Location</h4>
                  <ul className="contact_info_list">
                    {data && data.site_address && (
                      <p
                        className="mb-2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                      >
                        <i
                          className="fa-sharp fa-solid fa-location-dot me-3 fs-5"
                          style={{ color: "#764af1", paddingTop: "5px" }}
                        ></i>
                        {data && data.site_address
                          ? data && data.site_address
                          : ""}
                      </p>
                    )}

                    {data && data.site_phone && (
                      <p
                        className="mb-2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                      >
                        <i
                          className="fa-solid fa-phone me-3 fs-5"
                          style={{ color: "#764af1", paddingTop: "5px" }}
                        ></i>
                        {data.site_phone ? data.site_phone : ""}
                      </p>
                    )}

                    {data && data.site_mobile && (
                      <p
                        className="mb-2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                      >
                        <i
                          className="fa-solid fa-mobile  me-3 fs-5"
                          style={{ color: "#764af1", paddingTop: "5px" }}
                        ></i>
                        {data.site_mobile ? data.site_mobile : ""}
                      </p>
                    )}

                    {data && data.site_email && (
                      <p
                        className="mb-2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                      >
                        <i
                          className="fa-solid fa-envelope-open  me-3 fs-5"
                          style={{ color: "#764af1", paddingTop: "5px" }}
                        ></i>
                        {data.site_email ? data.site_email : ""}
                      </p>
                    )}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6  offset-md-2">
                <div className="footer-widget widget widget_nav_menu">
                  <h4 className="widget-headline">Quick Link</h4>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/partner-institutions">
                        Our Partner Institutions
                      </Link>
                    </li>
                    <li>
                      <Link to="/courses">Courses</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact US</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="footer-widget widget widget_nav_menu">
                  <h4 className="widget-headline">Quick Link</h4>
                  <div>
                    <div className="d-flex flex-row">
                      {data && data.site_fb_link && (
                        <a
                          className="btn btn-primary me-2"
                          href={`${data.site_fb_link ? data.site_fb_link : ""}`}
                          target="_blank"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      )}

                      {data && data.site_lin_link && (
                        <a
                          className="btn btn-primary me-2"
                          href={`${
                            data.site_lin_link ? data.site_lin_link : ""
                          }`}
                          target="_blank"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      )}

                      {data && data.site_tw_link && (
                        <a
                          className="btn btn-primary me-2"
                          href={`${data.site_tw_link ? data.site_tw_link : ""}`}
                          target="_blank"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      )}

                      {data && data.site_in_link && (
                        <a
                          className="btn btn-primary me-2"
                          href={`${data.site_in_link ? data.site_in_link : ""}`}
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      )}

                      {data && data.site_you_link && (
                        <a
                          className="btn btn-primary me-2"
                          href={`${
                            data.site_you_link ? data.site_you_link : ""
                          }`}
                        >
                          <i className="fa-brands fa-youtube"></i>
                        </a>
                      )}

                      {data && data.site_tik_link && (
                        <a
                          className="btn btn-primary me-2"
                          href={`${
                            data.site_tik_link ? data.site_tik_link : ""
                          }`}
                        >
                          <i className="fa-brands fa-tiktok"></i>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container custom-container-01">
          <div className="row">
            <div className="col-lg-12">
              <div className="copyright-area-inner">
                <p>
                  © 2023 {data && data.site_title ? data.site_title : ""}. All
                  rights reserved | Developed By{" "}
                  <a href="https://www.blackhawk.edu.np/" target="_blank">
                    <span className="text-primary">Black Hawk </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
