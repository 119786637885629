import axios from "axios";
import { linkUrl } from "../component/common/linkUrl";


const coursesService = async () => {
    const res = await axios.get(`${linkUrl}/courses`);
    if (res.data.status === 200) {
        return res.data.data;
    }
}

export default coursesService;
