import axios from "axios";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { linkUrl } from "../common/linkUrl";

const SliderComponent = () => {


  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    const sliderData= async()=>{
      setIsLoading(true);
      const res = await axios.get(`${linkUrl}/slider`);
      if (res.data.status === 200) {
          setData(res.data.data) ;
          setIsLoading(false);
      }
      else{
          setIsLoading(false);
      }

  };
  sliderData()


  },[])


  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className="row">
        <div>
       
        <Slider {...settings}>

          {
              data && data.sliders.length > 0 && data.imgurl &&
              data.sliders.slice(0,4).map((s,idx)=>(
                <div key={idx}>
                <img src={`${data.imgurl}/${s.slider_img}`} className=''  alt='Slider images' />
      
                </div>

              ))
          }
      

        </Slider>
      </div>
          

    </section>
  );
};

export default SliderComponent;
