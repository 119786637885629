import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import { imgUrl, linkUrl } from "../common/linkUrl";

const TestimonialHomeComponent = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const testimonialData = async () => {
      setIsLoading(true);
      const res = await axios.get(`${linkUrl}/testimonial`);
      if (res.data.status === 200) {
        setData(res.data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    testimonialData();
  }, []);

  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    arrows:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        
        },
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="testimonial-area-wrapper section-top-space bg-primary pb-3">
   
      <div className="container custom-container-01">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title-wrapper text-center">
              <h4 className="section-title text-capitalize mt-2 text-white">
               Student Testimonials
              </h4>
            </div>
          </div>
        </div>
        <div className="container">

       
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <Slider {...settings}>
              {data && data.length>0 && 
               data.slice(0,9).map((t,idx)=>(
                <div className="testimonial-single-items style-01 v-02 c-height " key={idx}>
                <div className="content">
                  <div className="fst-italic fw-bold" dangerouslySetInnerHTML={{ __html: t.std_saying.slice(0, 2000)}}>
                   
                  </div>
                </div>
       {
        t.img &&
        <div className="client-and-quote">
        <div className="client-details">
          <div className="thumb">
            <img
             src={
              t.img
                ? `${imgUrl}${t.img}`
                : ""
            }
            alt=""
            />
          </div>
          <div className="content">
            <p className="client-name text-primary">{t.name?t.name:""}</p>
            <p className="designation text-success">{t.course?t.course:""}</p>
            <p className="designation text-danger">{t.university?t.university:""}</p>

          </div>
        </div>
      </div>
       }
       
              </div>
              ))
 
              }
            </Slider>
          </div>
        </div>
        </div>
      </div>
    </section>
  );
};



export default TestimonialHomeComponent;

