import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { imgUrl, linkUrl } from '../component/common/linkUrl';

const CourseDetailPage = () => {
  window.scrollTo(0, 0);
    const { id } = useParams();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      const courseDetails = async () => {
        setIsLoading(true);
        if (id) {
          const res = await axios.get(`${linkUrl}/courses/${id}`);
          if (res.data.status === 200) {
            setData(res.data.data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        }
      };
      courseDetails();
    }, []);
  return (
    <>
    <div className="container mb-4">
      <div className="blog-grid-item-02 style-02 blog-details ">
        {data && data.course_name && (
          <h3 className="main-title mt-4">
            {data.course_name ? data.course_name : ""}
          </h3>
        )}
       
      </div>

      {data && data.course_des && (
        <div className="row">
          <div
            className="col-md-12 blog-details-content p-4 fs-6"
            style={{ backgroundColor: "#edeffc" }}
          >
             <img style={{ float:"left",width:"250px",height:"200px" ,marginRight:"10px" }}
              src={
                data.course_img
                  ? `${imgUrl}${data.course_img}`
                  : ""
              }
              alt="Course"
            />
            <p
              className="text-dark fw-bold"
              dangerouslySetInnerHTML={{ __html: data.course_des }}
            ></p>
          </div>
        </div>
      )}
    </div>
  </>
  )
}

export default CourseDetailPage