import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { imgUrl, linkUrl } from "../component/common/linkUrl";

const PartnerDetailPage = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const instituteDetails = async () => {
      setIsLoading(true);
      if (id) {
        const res = await axios.get(`${linkUrl}/institutes/${id}`);
        if (res.data.status === 200) {
          setData(res.data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    };
    instituteDetails();
  }, []);

  return (
    <>
      <div className="container mb-4">
        <div className="blog-grid-item-02 style-02 blog-details ">
          {data && data.institute_name && (
            <h3 className="main-title mt-4">
              {data.institute_name ? data.institute_name : ""}
            </h3>
          )}
         
        </div>

        {data && data.institute_des && (
          <div className="row">
            <div
              className="col-md-12 blog-details-content p-4 fs-6"
              style={{ backgroundColor: "#edeffc" }}
            >
               <img style={{ float:"left",width:"250px",height:"200px" ,marginRight:"10px" }}
                src={
                  data.institute_img
                    ? `${imgUrl}${data.institute_img}`
                    : ""
                }
                alt="Institute"
              />
              <p
                className="text-dark fw-bold"
                dangerouslySetInnerHTML={{ __html: data.institute_des }}
              ></p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PartnerDetailPage;
