import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import siteSetting from "../../services/settingService";
import { imgUrl, linkUrl } from "./linkUrl";

const NavBarComponent = () => {
  const [siteData, setSiteData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [abData, setAbData] = useState();

  useEffect(() => {
    const servicesData = async () => {
      setIsLoading(true);
      const service = await siteSetting();
      const res = await axios.get(`${linkUrl}/abroadstudy`);
      if (res.data.status === 200) {
        setAbData(res.data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }

      if (service) {
        setSiteData(service);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    servicesData();
  }, []);
  return (
    <div className="sticky-top">
      <div
        className="nav-area-wrapper-relative"
        style={{ minHeight: "100px", zIndex: "10000" }}
      >
        <nav
          className="navbar navbar-area navbar-expand-lg navigation-style-02"
          style={{ paddingTop: "6px", backgroundColor: "#f4f4fd" }}
        >
          <div className="container-fluid custom-container px-5">
            <div className="responsive-menu">
              <div className="">
                <Link to="/" className="logo">
                  <img
                    style={{ maxHeight: "50px", marginLeft: "" }}
                    src={
                      siteData &&
                      siteData.site_logo &&
                      siteData.site_logo !== null
                        ? `${imgUrl}${siteData.site_logo}`
                        : ""
                    }
                    alt="logo"
                  />
                
                </Link>
              </div>
              <button
                className="navbar-toggler navbar-bs-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#themeim_main_menu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className="collapse navbar-collapse" id="themeim_main_menu">
              <ul className="navbar-nav">
                <li>
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive ? "activeClass" : ""
                    }
                  >
                    Home
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/about-us"
                    className={({ isActive }) =>
                      isActive ? "activeClass" : ""
                    }
                  >
                    About Us
                  </NavLink>
                </li>
                {abData && abData.length > 0 && (
                  <li className="menu-item-has-children">
                    <Link to="#">Abroad Study</Link>
                    <ul className="sub-menu" style={{ display: "block" }}>
                      {abData &&
                        abData.length > 0 &&
                        abData.map((m, idx) => (
                          <li key={idx}>
                            <Link to={`study-abroad/${m.id}`}>
                              {m.abroad_studies_name}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                )}

                <li>
                  <NavLink
                    to="/partner-institutions"
                    className={({ isActive }) =>
                      isActive ? "activeClass" : ""
                    }
                  >
                    Our Partner Institutions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/courses"
                    className={({ isActive }) =>
                      isActive ? "activeClass" : ""
                    }
                  >
                    Courses
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/services"
                    className={({ isActive }) =>
                      isActive ? "activeClass" : ""
                    }
                  >
                    Services
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/gallery"
                    className={({ isActive }) =>
                      isActive ? "activeClass" : ""
                    }
                  >
                    Gallery
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/contact-us"
                    className={({ isActive }) =>
                      isActive ? "activeClass" : ""
                    }
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavBarComponent;
